import { faker } from "@faker-js/faker";
import { Assessment, bufferDecode } from "@idsk/components-props";
import { Registry, Response } from "miragejs";
import { RouteHandler } from "miragejs/server";
import { ModelRegistry } from "./MirageModels";
export function createAssessments(): any {
  const jobProfileId = [
    "Principal Software Engineer",
    "Sr. Software Engineer",
    "Sr. Back-End Developer",
  ];
  const roleMaturity = [
    "Beginner (01 - 04 Yrs)",
    "Advanced (06 - 12 Yrs)",
    "Intermediate (03 - 05 Yrs)",
  ];
  const clientId = ["idsk.internal.client.id", "self"];
  const status = ["Active", "Draft"];
  return {
    clientId: clientId[Math.floor(Math.random() * clientId.length)],
    title: faker.random.words(2),
    jobProfileId: jobProfileId[Math.floor(Math.random() * jobProfileId.length)],
    roleMaturity: roleMaturity[Math.floor(Math.random() * roleMaturity.length)],
    status: status[Math.floor(Math.random() * status.length)],
    jobRole: jobProfileId,
    duration: Math.floor(Math.random() * 1000),
    setting: {},
    addedQuestions: [
      { id: "1", clientId: "self" },
      { id: "2", clientId: "self" },
      { id: "3", clientId: "self" },
      { id: "4", clientId: "self" },
      { id: "5", clientId: "self" },
      { id: "6", clientId: "self" },
    ],
  };
}

export const mockGetAssessmentsDB: RouteHandler<
  Registry<typeof ModelRegistry, any>,
  any
> = (schema, request) => {
  const dbData = schema.all("assessment").models;
  const something = bufferDecode(request.requestBody);
  let response = {
    records: dbData,
    total: dbData.length,
  };
  return new Response(200, {}, { all: response });
};

export const mockGetAssessments: RouteHandler<
  Registry<typeof ModelRegistry, any>,
  any
> = (schema, request) => {
  const dbData = schema.all("assessments").models;
  return Object.fromEntries(
    (
      JSON.parse(request.requestBody) as {
        queryId: string;
        clientId: string;
        assessmentId: string;
        jobProfileId?: string[];
        roleMaturity?: string[];
        status?: string;
      }[]
    ).map(
      ({
        queryId,
        clientId,
        assessmentId,
        jobProfileId,
        roleMaturity,
        status,
      }) => {
        let resp = dbData.filter((el: any) => {
          if (queryId === "myAssessments") {
            return (el.attrs as any).clientId == clientId;
          }
          {
            return (el.attrs as any).clientId == clientId;
          }
        });
        let data = resp as Array<Partial<Assessment>>;
        if (jobProfileId) {
          data = data.filter((el) => {
            return queryId?.includes(el.jobProfileId ?? "");
          });
        }
        if (roleMaturity) {
          data = data.filter((el) => {
            return roleMaturity?.includes(el.roleMaturity ?? "");
          });
        }
        if (status) {
          data = data.filter((el) => {
            return status == el.status;
          });
        }

        let response = {
          records: data,
          total: data.length,
        };
        return [
          queryId,
          {
            ...response,
            records: data.splice(
              parseInt(request.queryParams.from as string),
              parseInt(request.queryParams.size as string)
            ),
          },
        ];
      }
    )
  );
};

export const mockPostAssessment: RouteHandler<
  Registry<typeof ModelRegistry, any>,
  any
> = (schema, request) => {
  let codingQuestion = JSON.parse(request.requestBody);
  let response = schema.create("assessments", codingQuestion);
  return new Response(200, {}, { id: response.id });
};
export const mockGetAssessmentById: RouteHandler<
  Registry<typeof ModelRegistry, any>,
  any
> = (schema, request) => {
  const dbData = schema.all("assessments").models;
  const application = dbData.find(
    (item) => (item?.attrs as any)?.id === request.params.id
  );
  if (application) {
    return new Response(200, {}, application.attrs);
  } else {
    return new Response(404, {}, { errorMessage: "Not found" });
  }
};
export const mockPatchAssessmentById: RouteHandler<
  Registry<typeof ModelRegistry, any>,
  any
> = (schema, request) => {
  let assessmentDetails = JSON.parse(request.requestBody);
  const dbData = schema.all("assessments").models;
  const application = dbData.find(
    (item) => (item?.attrs as any)?.id === request.params.id
  );
  if (application) {
    application.update(assessmentDetails);
    return new Response(200, {}, "SUCCESS");
  } else {
    return new Response(404, {}, { errorMessage: "Not found" });
  }
};
