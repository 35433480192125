export const region = "ap-south-1";
export const mandatorySignIn = true;
export const scope = ["email", "openid", "profile"];
export const responseType = "code";

export const localhost = "localhost";
export const devAppLink = "dev-campus.interviewdesk.in";
export const preProdAppLink = "pp-campus.interviewdesk.in";
export const prodAppLink = "campus.interviewdesk.in";

export const dev = "development";
export const preprod = "preprod";
export const prod = "prod";

export const redirectBaseUrls = {
  [dev]:
    "https://idsk-coreinfracdkdevwave-dev-campus.auth.ap-south-1.amazoncognito.com/login?client_id=52gb8m1e25ab6hd3t7th6prnqk&response_type=code&scope=email+openid+profile&redirect_uri=",
  [preprod]:
    "https://idsk-coreinfracdkpreprodwave-preprod-campus.auth.ap-south-1.amazoncognito.com/login?client_id=4v13qcd6ebfaejhqfqo7mgh8ci&response_type=code&scope=email+openid+profile&redirect_uri=",
  [prod]:
    "https://idsk-coreinfracdkprodwave-prod-campus.auth.ap-south-1.amazoncognito.com/login?client_id=1g66o0uigcmhh2s6gu3r8sldd1&response_type=code&scope=email+openid+profile&redirect_uri=",
};

export const unAuthCognitoIdentityPoolIds = {
  [dev]: "ap-south-1:3e3b081e-8861-4f73-97f0-806d69134a94",
  [preprod]: "ap-south-1:75ac10ea-dcc6-4612-8ca2-23016241654b",
  [prod]: "ap-south-1:af2eac06-d4b5-48ae-8e6e-beecefa80275",
};
