import {
  Assessment,
  AssessmentSession,
  AssessmentSessionQuestion,
  PartialQuestionFormType,
  safeBase64Decode,
} from "@idsk/components-props";
import { Registry, Response } from "miragejs";
import { RouteHandler } from "miragejs/server";
import { ModelRegistry } from "./MirageModels";
import moment from "moment";
import { faker } from "@faker-js/faker";

export function getAssessmentData(): AssessmentSession {
  let status = [
    "NOT_STARTED",
    "IN_PROGRESS",
    "COMPLETED",
    "TIMED_OUT",
    "ABANDONED",
    "ERROR",
    "STARTED",
  ];

  return {
    title: faker.lorem.words(4),
    jobProfileId: faker.datatype.uuid(),
    roleMaturity: faker.lorem.words(2),
    duration: 1,
    // @ts-ignore
    status: status[faker.datatype.number({ min: 0, max: 6, precision: 1 })],
    totalScore: faker.datatype
      .number({
        min: 45,
        max: 100,
        precision: 1,
      })
      .toString(),
    id: faker.datatype.uuid(),
    clientId: faker.datatype.uuid(),
    userId: faker.datatype.uuid(),
    sessionStartTime: faker.date
      .soon(faker.datatype.number({ min: 2, max: 10 }))
      .toLocaleDateString(),
    currentServerTime: faker.date
      .soon(faker.datatype.number({ min: 2, max: 10 }))
      .toLocaleDateString(),
    candidateName: faker.name.fullName(),
    companyName: faker.company.name(),
    companyLogo: faker.image.imageUrl(),
    questions: [
      {
        questionTestCases: [
          {
            testCaseName: faker.lorem.words(4),
            input: faker.lorem.words(6),
            output: faker.lorem.words(7),
            sample: faker.datatype.boolean(),
          },
        ],
        sectionIndex: faker.datatype.number(),
        providedAnswer: {
          code: faker.lorem.words(6),
          language: faker.lorem.words(2),
        },
        markedForReview: false,
        answerType: faker.lorem.words(5),
        answerOptions: [],
        score: faker.datatype.number(),
        codingLanguage: [
          {
            id: 50,
            memoryLimit: 2048,
            timeLimit: 1,
          },
          {
            id: 63,
            memoryLimit: 2048,
            timeLimit: 1,
          },
        ],
        questionType: faker.lorem.word(),
        questionTitle: faker.lorem.words(3),
        category: faker.lorem.slug(),
        topics: faker.lorem.words(3),
        concept: faker.lorem.words(4),
        otherTags: [faker.lorem.word()],
        description: faker.lorem.words(4),
        difficulty: faker.lorem.words(4),
        duration: faker.datatype.number(),
        id: faker.datatype.uuid(),
        clientId: faker.datatype.uuid(),
      },
    ],
  };
}

export const mockGetAssessmentSessionList: RouteHandler<
  Registry<typeof ModelRegistry, any>,
  any
> = (schema, request) => {
  const sampleData = schema.all("assessmentSession").models;
  const qp = request.queryParams;
  const from = parseInt((qp?.from as string) ?? "0");
  const size = parseInt((qp?.size as string) ?? "100");
  const expiredData = sampleData.slice(
    faker.datatype.number({ min: 0, max: sampleData.length / 5, precision: 1 }),
    faker.datatype.number({
      min: sampleData.length / 5,
      max: sampleData.length,
      precision: 1,
    })
  );

  function checkIfCompleted(val: any) {
    return (
      val === "COMPLETED" ||
      val === "TIMED_OUT" ||
      val === "ABANDONED" ||
      val === "ERROR"
    );
  }

  function checkIfNotCompleted(val: any) {
    return val === "NOT_STARTED" || val === "IN_PROGRESS" || val === "STARTED";
  }

  return {
    activeSessions: {
      total: sampleData.length,
      records: sampleData
        .slice(from, from + size)
        // @ts-ignore
        .filter((i) => checkIfNotCompleted(i.status)),
    },
    inActiveSessions: {
      total: expiredData.length,
      records: expiredData
        .slice(from, from + size)
        // @ts-ignore
        .filter((i) => checkIfCompleted(i.status)),
    },
  };
};

export const mockGetAssessmentSessionById: RouteHandler<
  Registry<typeof ModelRegistry, any>,
  any
> = (schema, _request) => {
  const dbData = schema.all("assessments").models;
  const questionDbData = schema.all("questions").models;
  const { sections, ...assessment } = dbData.pop()
    ?.attrs as Partial<Assessment>;
  const questions = questionDbData
    .slice(0, 30)
    .map<AssessmentSessionQuestion>((el, index) => {
      const questionData = el.attrs as PartialQuestionFormType;
      const {
        questionTestCases,
        score,
        correctAnswers,
        parameters,
        functionName,
        returnType,
        ...questionDetails
      } = el.attrs as PartialQuestionFormType;

      const questionTestCase = questionData.questionTestCases?.map(
        (testcase, _index) => {
          const { title, score, difficulty, ...test } = testcase;
          return {
            ...test,
          };
        }
      );

      return {
        ...questionDetails,
        questionTestCases: questionTestCase,
        id: "" + index,
        providedAnswer:
          index % 2
            ? questionDetails.questionType === "MCQ"
              ? [questionDetails.answerOptions?.[1]]
              : {
                  language: "63",
                  code: window.btoa(
                    window.encodeURIComponent('console.log("hello, world");\n')
                  ),
                }
            : undefined,
      } as AssessmentSessionQuestion;
    });
  let serverTime = moment().toISOString();
  const assessmenSessiondata = {
    ...assessment,
    status: "IN_PROGRESS",
    duration: 60,
    sessionStartTime: serverTime,
    sections: [{ questions: questions, title: "section-1" }],
    candidateName: "virat",
    companyLogo: "https://img.freepik.com/free-icon/microsoft_318-566086.jpg",
    companyName: "MicroSoft",
  } as AssessmentSession;
  return new Response(200, {}, assessmenSessiondata);
};

export const mockRunCode: RouteHandler<
  Registry<typeof ModelRegistry, any>,
  any
> = (_schema, request) => {
  console.log("request", request);

  return {
    customTestCase: {
      input: "19",
      output: "20",
      result: true,
    },
    validatedTestCases: [
      {
        input: "10",
        output: "20",
        result: true,
        sample: true,
        testCaseName: "Remove Vowels",
      },
      {
        input: "20",
        output: "30",
        result: false,
        sample: true,
        testCaseName: "Remove Character",
      },
      {
        input: "40",
        output: "50",
        result: false,
        sample: true,
        testCaseName: "TestCase",
      },
    ],
  };
};

export const mockPatchAssessmentSessionById: RouteHandler<
  Registry<typeof ModelRegistry, any>,
  any
> = (_schema, request) => {
  const requestBody = safeBase64Decode(request.requestBody);
  return new Response(
    200,
    {},
    {
      status: "success",
      currentServerTime: moment().toISOString(),
    }
  );
};
