import { AssessmentSession, Question } from "@idsk/components-props";
import {
  AsyncRequest,
  PutResponse,
  generateD2SCRUDHooks,
  getAxiosInstance,
} from "@idsk/ui-core-framework";
import { QueryObserverOptions } from "@tanstack/react-query";

export {
  RunCodeRequest as AssessmentSessionRunCodeRequest,
  RunCodeDAO as AssessmentSessionRunCodeDAO,
} from "@idsk/questionbank";

export interface AssessmentSessionPatchResponse extends PutResponse {
  currentServerTime: string;
}

export const {
  useGetHook: useAssessmentSessionGetHook,
  usePostHook: useAssessmentSessionPostHook,
  usePatchHook: useAssessmentSessionPatchHook,
} = generateD2SCRUDHooks<AssessmentSession>(
  "assessmentSessions",
  "assessmentsessions",
  getAxiosInstance(),
  false
);
export class AssessmentSessionDAO extends AsyncRequest<AssessmentSession> {
  constructor(
    private readonly clientId: string,
    private readonly id: string,
    private readonly type: string,
    private readonly readonly: boolean
  ) {
    super();
  }

  getConfig = (): QueryObserverOptions<any, Error> => {
    return {
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      keepPreviousData: false,
    };
  };

  getAsyncFunction = async (): Promise<AssessmentSession> => {
    const params = new URLSearchParams();
    params.append(this.type, this.id);
    if (this.readonly) {
      params.append("showSolution", "true");
    }

    const url = `/v1/d2s/assessmentsessions/clients/${
      this.clientId
    }/assessmentSessions?${params.toString()}`;

    const res = await getAxiosInstance().get(url, {
      headers: { useMirage: false },
    });
    return res.data;
  };

  getCacheKey = (): string[] => {
    return [
      "AssessmentSession",
      this.clientId,
      `${this.readonly}`,
      this.id,
      this.type,
    ];
  };
}
