import { ThemeProvider } from "styled-components";
import { THEME } from "./theme";
// import { UserContext } from "./global-context/UserContext";
import {
  UserContext,
  attachHandlers,
  getStageType,
} from "@idsk/ui-core-framework";
import { RootContainer } from "./container/RootContainer";
import { Amplify, Auth, Hub } from "aws-amplify";
import { amplifyConfig } from "./cognito/cognitoconfig";
import { getListener } from "./cognito/cognitoEventHandlers";
import { useEffect, useState } from "react";
import { createUserContextFromCognito } from "./cognito/cognitoUtils";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter } from "react-router-dom";
import AWS from "aws-sdk";
import { region, unAuthCognitoIdentityPoolIds } from "./cognito/constants";
import { ThemeWrapperLayout } from "./container/ThemeWrapper.layout";
import {
  ThemeWrapperDataFetcher,
  ThemeWrapperProps,
} from "./container/ThemeWrapper.df";
import { LoginContainer } from "./container/LoginContainer";
import { ApiProvider, SectionApiProvider } from "@idsk/lms-web-app";

Amplify.configure(amplifyConfig);
const environment = getStageType();
AWS.config.region = region;
const credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: unAuthCognitoIdentityPoolIds[environment],
});
const ThemeWrapperPage = attachHandlers<
  ThemeWrapperProps,
  { children: JSX.Element }
>("ThemeWrapperPage")(ThemeWrapperDataFetcher)(ThemeWrapperLayout);
const queryClient = new QueryClient();
function App() {
  const defaultUser = process.env.SKIP_LOGIN ? "" : null;

  const [user, setUser] = useState(defaultUser);
  const addUnAuthCredentialsToSessionStorage = () => {
    credentials.get((err: AWS.AWSError | undefined) => {
      if (err) {
        console.error("error getting credentials = ", err.message);
        return;
      }
      localStorage.setItem("accessKey", credentials.accessKeyId);
      localStorage.setItem("secretKey", credentials.secretAccessKey);
      localStorage.setItem("sessionToken", credentials.sessionToken);
    });
  };

  useEffect(() => {
    const listener = getListener(setUser);
    Hub.listen("auth", listener);
    addUnAuthCredentialsToSessionStorage();
    Auth.currentAuthenticatedUser()
      .then((currentUser) => {
        setUser(currentUser);
        localStorage.setItem(
          "idToken",
          `CognitoIdentityServiceProvider.${amplifyConfig.Auth.userPoolWebClientId}.${currentUser.username}.idToken`
        );
      })
      .catch((err) => {
        console.log(
          "Error occured, while trying to get authenticated user. Probably the user is not logged in",
          err
        );
      });
  }, []);

  let values = createUserContextFromCognito(
    user,
    amplifyConfig.Auth.userPoolWebClientId
  );

  return (
    <UserContext.Provider value={{ ...values, setUser }}>
      <ApiProvider>
        <SectionApiProvider>
          <ThemeWrapperPage
            children={
              !values.isSignedIn ? <LoginContainer /> : <RootContainer />
            }
          />
        </SectionApiProvider>
      </ApiProvider>
    </UserContext.Provider>
  );
}

export default App;
