import { UserPolicyGroup } from "@idsk/components-props";
import { AsyncRequest, getAxiosInstance } from "@idsk/ui-core-framework";
import { QueryObserverOptions } from "@tanstack/react-query";

export class GetUserRoleDAO extends AsyncRequest<UserPolicyGroup[]> {
  constructor(
    private readonly clientId: string,
    private readonly userId: string
  ) {
    super();
  }

  getAsyncFunction = async (): Promise<UserPolicyGroup[]> => {
    let url = `/clients/${this.clientId}/users/${this.userId}/roles`;
    const res = await getAxiosInstance().get(url, {
      headers: {
        useMirage: false,
      },
    });
    console.log("res.data = ", res.data);
    return res.data;
  };

  getConfig = (): QueryObserverOptions<any, Error> => {
    return {
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      keepPreviousData: false,
      enabled: !!this.clientId && !!this.userId,
    };
  };
  getCacheKey = (): string[] => {
    return ["GetUserRole", this.clientId, this.userId];
  };
}
