import { ThemeProvider } from "styled-components";
import { ThemeWrapperProps } from "./ThemeWrapper.df";
import { IDTheme, IDeskUIProps } from "@idsk/components-props";

export const ThemeWrapperLayout = (props: ThemeWrapperProps) => {
  const companyLogo = props.asyncSearchClientUI.getResponse()?.records
    ? props.asyncSearchClientUI.getResponse()?.records[0].logoS3FileName
    : undefined;
  const companyBanner = props.asyncSearchClientUI.getResponse()?.records
    ? props.asyncSearchClientUI.getResponse()?.records[0].clientBanner
    : undefined;
  const theme: IDTheme = {
    name: "dark",
    text: "#000000",
    companyLogo: companyLogo,
    companyBanner: companyBanner,
  };
  return (
    <props.asyncSearchClientUI.Wrapper>
      <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
    </props.asyncSearchClientUI.Wrapper>
  );
};
