// modules/web-app/src/dao/Question.dao.ts

import { QueryObserverOptions } from "@tanstack/query-core";
import {
  AsyncRequest,
  MultiListingDaoOutput,
  UserContext,
} from "@idsk/ui-core-framework";
import { RcFile } from "antd/lib/upload";
import { getStageType } from "@idsk/ui-core-framework";
import {
  AssessmentCodingProvidedAnswer,
  CodeResult,
  PartialQuestionFormType,
} from "@idsk/components-props";
import {
  Entity,
  generateD2SCRUDHooks,
  getAxiosInstance,
} from "@idsk/ui-core-framework";
import { Buffer } from "buffer";
import { AxiosResponse } from "axios";
import JSZip from "jszip";
import { AssessmentUploadAxiosInstance } from "@idsk/ui-core-framework";

// Generate hooks for CRUD operations
export const {
  useGetHook: useQuestionFormGetHook,
  usePostHook: useQuestionFormPostHook,
  usePatchHook: useQuestionFormPatchHook,
} = generateD2SCRUDHooks<PartialQuestionFormType & Entity>(
  "assessmentItems",
  "questionandtests",
  getAxiosInstance(),
  false
);

// Response interface for the question list
export interface QuestionListResponse {
  total: number;
  records: PartialQuestionFormType[];
}

// Request interface for running code
export interface RunCodeRequest extends Entity {
  questionId: string;
  questionClientId: string;
  providedAnswer: AssessmentCodingProvidedAnswer;
  input?: string;
  output?: string;
  testCaseType: "SAMPLE" | "ALL";
}

// DAO class for fetching questions
export class GetQuestionsDao extends AsyncRequest<
  MultiListingDaoOutput<QuestionListResponse>
> {
  private readonly contentFrom: number;

  constructor(
    private readonly pageNumber: number,
    private readonly pageSize: number,
    private readonly clientId: string,
    private readonly queryParams?: object[]
  ) {
    super();
    this.contentFrom = (this.pageNumber - 1) * this.pageSize;
  }

  getAsyncFunction = async (): Promise<
    MultiListingDaoOutput<QuestionListResponse>
  > => {
    const url = `/v1/d2s/questionandtests/search/clients/${this.clientId}/assessmentItems?from=${this.contentFrom}&size=${this.pageSize}`;
    try {
      const res = await getAxiosInstance().post(
        url,
        Buffer.from(JSON.stringify(this.queryParams)).toString("base64"),
        {
          headers: {
            useMirage: false,
          },
        }
      );
      return res.data;
    } catch (error) {
      // Handle error appropriately
      console.error("Error fetching questions: ", error);
      throw new Error("Failed to fetch questions.");
    }
  };

  // Configurations for the query
  getConfig = (): QueryObserverOptions<any, Error> => {
    return {
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      keepPreviousData: false,
      enabled: !!this.queryParams,
    };
  };

  getCacheKey = (): string[] => {
    return [
      "GetQuestionData Value",
      this.contentFrom.toString(),
      this.pageSize.toString(),
      JSON.stringify(this.queryParams),
    ];
  };
}

// DAO class for running code
export class RunCodeDAO extends AsyncRequest<CodeResult, RunCodeRequest> {
  private abortController: AbortController | undefined;
  private clientId: string | null = null;

  constructor() {
    super();
  }

  abortExecution = () => {
    this.abortController?.abort();
  };

  setClientId = (clientId: string) => {
    this.clientId = clientId;
  };

  // Configurations for the query
  getConfig = (): QueryObserverOptions<any, Error> => {
    return {
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      keepPreviousData: false,
    };
  };

  // Async function to run code
  getAsyncFunction = async (payload: RunCodeRequest): Promise<CodeResult> => {
    return new Promise(async (resolve, reject) => {
      const pollerInterval = 2000; // 2 seconds
      let pollerCount = 30;

      try {
        this.abortController = new AbortController();
        const url = `/v1/d2s/assessmentsessions/clients/${this.clientId}/codingQuestionTestRuns`;
        const abortSignal = this.abortController.signal;

        const res = await getAxiosInstance().post<
          RunCodeRequest,
          AxiosResponse<CodeResult>
        >(url, payload, {
          headers: { useMirage: false },
          signal: abortSignal,
        });

        if (!res.data?.id) {
          return reject(new Error("Unable to run. Please try again."));
        } else {
          while (pollerCount--) {
            // Sleep for a while
            await new Promise((resolve) => setTimeout(resolve, pollerInterval));

            // Check the status
            const runResp = await getAxiosInstance().get<
              RunCodeRequest,
              AxiosResponse<CodeResult>
            >(`${url}/${res.data.id}`, {
              headers: { useMirage: false },
              signal: this.abortController?.signal,
            });

            if (runResp.status === 200 && runResp.data?.status === "FINAL") {
              return resolve({ ...runResp.data, id: res.data?.id });
            }
          }
          return reject(
            new Error("Unable to finish execution. Please try again.")
          );
        }
      } catch (error: any) {
        if (error.code === "ERR_CANCELED") {
          reject(new Error("Execution was canceled."));
        } else if (error.response) {
          reject(
            new Error(error.response.data?.message ?? "Unknown error occurred.")
          );
        } else {
          reject(error);
        }
      }
    });
  };

  getCacheKey = (): string[] => {
    return [];
  };
}

export const saveQuestionWithCodeTemplates = async (
  questionData: PartialQuestionFormType & Entity,
  codeTemplates: any[],
  isEditable: boolean,
  clientId: string,
  questionClientId?: string,
  id?: string
): Promise<void> => {
  try {
    const clientIdFinal = isEditable ? questionClientId! : clientId;
    let initialResponse: Entity;
    if (isEditable) {
      if (!id) {
        throw new Error("Question ID ('id') is required for editing.");
      }
      const patchUrl = `/v1/d2s/questionandtests/clients/${clientIdFinal}/assessmentItems/${id}`;
      const patchResponse = await getAxiosInstance().patch<
        PartialQuestionFormType & Entity
      >(patchUrl, questionData);
      initialResponse = patchResponse.data;
    } else {
      const postUrl = `/v1/d2s/questionandtests/clients/${clientIdFinal}/assessmentItems`;
      const postResponse = await getAxiosInstance().post<
        PartialQuestionFormType & Entity
      >(postUrl, questionData);

      if (!postResponse.data || !postResponse.data.id) {
        throw new Error(
          "Failed to retrieve question ID from the post response."
        );
      }

      initialResponse = postResponse.data;
    }

    const questionId = id || initialResponse.id;
    const zip = new JSZip();
    codeTemplates.forEach((file) => {
      zip.file(file.path, file.code, {
        comment: JSON.stringify({ readOnly: file.readOnly }),
      });
    });

    const zipContent = await zip.generateAsync({ type: "arraybuffer" });
    const zipBlob = new Blob([zipContent], { type: "application/zip" });
    const zipFile = new File([zipBlob], "codeTemplates.zip", {
      type: "application/zip",
    });
    let rcZipFile: RcFile;
    try {
      rcZipFile = zipFile as RcFile;
    } catch (castingError) {
      console.warn("Casting File to RcFile failed. Using File directly.");
      rcZipFile = zipFile as any;
    }

    const uploadUrl = `/frontendframework/questions/clients/${clientIdFinal}/${questionId}/codeTemplates.zip`;
    const uploadResponse = await AssessmentUploadAxiosInstance({
      url: uploadUrl,
      data: rcZipFile,
      method: "POST",
      headers: {
        "Content-Type": "application/zip",
      },
    });

    if (uploadResponse.status !== 200) {
      throw new Error(
        `Failed to upload code templates to S3. Status: ${uploadResponse.status}`
      );
    }

    const environment =
      getStageType() === "development" ? "dev" : getStageType();
    const s3Link = `https://idesk-${environment}-assessment.s3.amazonaws.com/frontendframework/questions/clients/${clientIdFinal}/${questionId}/codeTemplates.zip`;

    const updatedQuestionData = {
      ...questionData,
      codeTemplatesLink: s3Link,
    };
    const updateUrl = `/v1/d2s/questionandtests/clients/${clientIdFinal}/assessmentItems/${questionId}`;
    await getAxiosInstance().patch<PartialQuestionFormType & Entity>(
      updateUrl,
      updatedQuestionData
    );
  } catch (error: any) {
    console.error("Error in saveQuestionWithCodeTemplates:", error);
    throw error;
  }
};
