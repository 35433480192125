import { Navigate, Route, Routes } from "react-router-dom";
import { QuestionBankRoutes } from "@idsk/questionbank";
import { AssessmentRoutes } from "@idsk/assessment";
import { AssessmentAdministrationRoutes } from "@idsk/assessment-administration-app";
import { UserGuideRoutes } from "@idsk/userguide-app";
import { CampusAdministrationRoutes } from "@idsk/campus-administration-app";
import { StudentDataRoutes } from "@idsk/candidate-student";
import { CandidateGroupRoutes } from "@idsk/candidate-group";
import { LmsRouter } from "@idsk/lms-web-app";
import { AdminRouter } from "@idsk/admin-web-app";
import { UserGroupRoutes } from "@idsk/user-group-app";
import { UserManagementRoutes } from "@idsk/user-management-app";
import { AssessmentDashboardRoutes } from "@idsk/assessment-admin-dashboard";

import {
  UserRoleActions,
  permissionGroupAccessConstants,
  permissionAccessConstants,
} from "@idsk/components-props";
import { UserAccessWrapper } from "@idsk/components-ui";
import { QuestionFormPage } from "./codeIde";
import { attachHandlers } from "@idsk/ui-core-framework";
import {
  useQuestionAddFormDataFetcher,
  QuestionFormProps,
  useQuestionViewFormDataFetcher,
} from "./codeIde/QuestionForm.df";
import { CandidateReportRoutes } from "@idsk/candidate-report";

import { CodeIdeReports } from "./codeIde";
import {
  AssessmentSessionDataFetcher,
  AssessmentSessionLayoutProps,
} from "./codeIde/CodeIdeReports/DataFetchers/AssessmentSession.df";

// Existing layouts for QuestionForm
const QuestionFormLayout = attachHandlers<QuestionFormProps>(
  "QuestionFormLayout"
)(useQuestionAddFormDataFetcher)(QuestionFormPage);

const QuestionViewLayout = attachHandlers<QuestionFormProps>(
  "QuestionViewLayout"
)(useQuestionViewFormDataFetcher)(QuestionFormPage);

const CodeIdeReportsLayout = attachHandlers<
  AssessmentSessionLayoutProps,
  { type: string; readonly?: boolean }
>("CodeIdeReportsLayout")(AssessmentSessionDataFetcher)(CodeIdeReports);

export const RootRouter = () => {
  return (
    <Routes>
      <Route index element={<Navigate to={"/dashboard"} replace={true} />} />
      <Route path="*" element={<Navigate to={"/dashboard"} replace={true} />} />
      <Route path="/dashboard" element={<AssessmentDashboardRoutes />} />
      {/* Specific route for adding a new question */}
      <Route
        path="/clients/:clientId/questions/FRAMEWORK/add"
        element={<QuestionFormLayout />}
      />

      {/* Specific route for viewing/editing a question */}
      <Route
        path="/clients/:clientId/questions/FRAMEWORK/:id"
        element={<QuestionViewLayout />}
      />

      <Route
        path="/clients/:clientId/questions/*"
        element={<QuestionBankRoutes />}
      />

      {/* Other routes */}
      <Route path="/users/*" element={<StudentDataRoutes />} />
      <Route
        path="/clients/:clientId/assessments/*"
        element={<AssessmentRoutes />}
      />
      <Route path="/groups/*" element={<CandidateGroupRoutes />} />
      <Route
        path="/clients/:clientId/assessmentadministrations/*"
        element={<AssessmentAdministrationRoutes />}
      />
      <Route path="/userguide/*" element={<UserGuideRoutes />} />
      <Route
        path="/clients/:clientId/students/*"
        element={<CampusAdministrationRoutes />}
      />
      <Route path="/lms/*" element={<LmsRouter />} />
      <Route path="/admin/*" element={<AdminRouter />} />
      <Route
        path="/manage/users/*"
        element={
          <UserAccessWrapper
            permission={UserRoleActions.View}
            policyGroupName={permissionGroupAccessConstants.userManagement}
            policyName={permissionAccessConstants.users}
          >
            <UserManagementRoutes />
          </UserAccessWrapper>
        }
      />

      {/* Updated route for CodeIdeReportsLayout */}
      <Route
        path="/clients/:clientId/assessment-reports/:id/codeIde"
        element={<CodeIdeReportsLayout type="sessionId" readonly={true} />}
      />
      <Route
        path="/manage/college/*"
        element={
          <UserAccessWrapper
            permission={UserRoleActions.View}
            policyGroupName={permissionGroupAccessConstants.general}
            policyName={permissionAccessConstants.organizations}
          >
            <UserGroupRoutes />
          </UserAccessWrapper>
        }
      />
      <Route
        path="/manage/candidate-reports/*"
        element={
          <UserAccessWrapper
            permission={UserRoleActions.View}
            policyGroupName={permissionGroupAccessConstants.reports}
            policyName={permissionAccessConstants.reports}
          >
            <CandidateReportRoutes />
          </UserAccessWrapper>
        }
      />

      <Route
        path="/manage/groups/*"
        element={
          <UserAccessWrapper
            permission={UserRoleActions.View}
            policyGroupName={permissionGroupAccessConstants.userManagement}
            policyName={permissionAccessConstants.groups}
          >
            <UserGroupRoutes />
          </UserAccessWrapper>
        }
      />
    </Routes>
  );
};
