import { useContext } from "react";
import { useParams } from "react-router-dom";
import {
  useQuestionFormGetHook,
  useQuestionFormPostHook,
  useQuestionFormPatchHook,
  saveQuestionWithCodeTemplates,
} from "../dao/Question.dao";
import {
  AsyncPostAndPatchUI,
  AsyncGetAndPatchUI,
  useAsyncPostAndPatchUI,
  useAsyncGetAndPatchUI,
} from "@idsk/components-ui";
import { Entity, UserContext } from "@idsk/ui-core-framework";
import { PartialQuestionFormType } from "@idsk/components-props";

export interface QuestionFormProps {
  asyncQuestionFormUi:
    | AsyncPostAndPatchUI<PartialQuestionFormType & Entity>
    | AsyncGetAndPatchUI<PartialQuestionFormType & Entity>;
  newCodingQuestionForm?: boolean;
  readOnly?: boolean;
  onCancel?: () => void;
  isEditable?: boolean;
  questionEntity?: Entity;
}

export interface QuestionDrawerAdditionalProps {
  onCancel?: () => void;
  questionEntity?: Entity;
  questionType?: string;
  status?: string;
  readOnly?: boolean;
}

export interface FileState {
  path: string;
  code: string;
  readOnly: boolean;
}

export const useSaveQuestionWithCodeTemplates = () => {
  const { clientId } = useContext(UserContext);
  const { id } = useParams<{ id?: string }>();

  const isEditable = !!id;

  return async (
    questionData: PartialQuestionFormType & Entity,
    codeTemplates: FileState[],
    questionClientId?: string
  ): Promise<any> => {
    try {
      const response = await saveQuestionWithCodeTemplates(
        questionData,
        codeTemplates,
        isEditable,
        clientId || "",
        questionClientId,
        id
      );
      return response;
    } catch (error: any) {
      throw error;
    }
  };
};

export const useQuestionAddFormDataFetcher = (): QuestionFormProps => {
  const postHook = useQuestionFormPostHook();
  const patchHook = useQuestionFormPatchHook();

  const asyncQuestionFormUi = useAsyncPostAndPatchUI(postHook, patchHook);

  return {
    asyncQuestionFormUi,
    newCodingQuestionForm: true,
    isEditable: false,
  };
};

export const useQuestionViewFormDataFetcher = (): QuestionFormProps => {
  const { id, clientId } = useParams<{ id: string; clientId: string }>();

  if (!id || !clientId) {
    // Handle error or throw
    throw new Error("Question ID and Client ID are required.");
  }

  const getHook = useQuestionFormGetHook(clientId, id);
  const patchHook = useQuestionFormPatchHook();

  const asyncQuestionFormUi = useAsyncGetAndPatchUI(getHook, patchHook);

  return {
    asyncQuestionFormUi,
    newCodingQuestionForm: false,
    isEditable: true,
  };
};

export const useQuestionDrawerViewDataFetcher = (
  props: QuestionDrawerAdditionalProps
): QuestionFormProps => {
  const getHook = useQuestionFormGetHook(props.questionEntity?.id);
  const patchHook = useQuestionFormPatchHook();

  const asyncQuestionFormUi = useAsyncGetAndPatchUI(getHook, patchHook);

  return {
    asyncQuestionFormUi,
    onCancel: props.onCancel,
    questionEntity: props.questionEntity,
    newCodingQuestionForm: props.status === "DRAFT",
    isEditable: true,
  };
};
