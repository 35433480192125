import React, { useEffect, useState, useMemo } from "react";
import { Button, Select, Typography, Layout, message } from "antd";
import {
  SandpackCodeEditor,
  SandpackLayout,
  SandpackPreview,
  SandpackProvider,
  SandpackPredefinedTemplate,
  useSandpack,
} from "@codesandbox/sandpack-react";
import { Extension } from "@codemirror/state";
import { EditorView } from "@codemirror/view";
import SandpackFileExplorer from "@rainetian/sandpack-file-explorer";
import "./CreateQuestionForm.scss";

const { Option } = Select;
const { Title } = Typography;
const { Content } = Layout;

interface CreateQuestionFormProps {
  framework: string;
  onSaveTemplate: (fileStates: FileState[]) => void;
  codeTemplates: FileState[];
  onLanguageChange: (framework: string) => void;
}

interface FileState {
  path: string;
  code: string;
  readOnly: boolean;
}

interface SandpackLoggerProps {
  onSave: (fileStates: FileState[]) => void;
  selectedFiles: string[];
}

const CreateQuestionForm: React.FC<CreateQuestionFormProps> = ({
  framework,
  onSaveTemplate,
  codeTemplates,
  onLanguageChange,
}) => {
  const [selectedFiles, setSelectedFiles] = useState<string[]>([]);
  const sandpackFiles = useMemo(() => {
    if (codeTemplates && codeTemplates.length > 0) {
      const files = codeTemplates.reduce((acc, template) => {
        acc[template.path] = {
          code: template.code,
          hidden: false,
        };
        return acc;
      }, {} as Record<string, { code: string; hidden?: boolean }>);
      return files;
    } else {
      return undefined;
    }
  }, [codeTemplates]);

  useEffect(() => {
    onLanguageChange(framework);
    message.info(`Language set to ${framework}`);
    setSelectedFiles([]);
  }, [onLanguageChange]);

  useEffect(() => {
    if (codeTemplates && codeTemplates.length > 0) {
      const readOnlyFiles =
        codeTemplates
          ?.filter((template) => template.readOnly)
          .map((template) => template.path) || [];
      setSelectedFiles(readOnlyFiles);
    }
  }, [codeTemplates]);

  const handleFileChange = (newSelection: string[]) => {
    console.log("New Selection: ", newSelection);
    setSelectedFiles(newSelection);
  };

  return (
    <Layout style={{ backgroundColor: "#fff" }}>
      <Title level={4} style={{ marginBottom: "1rem" }}>
        Select Language
      </Title>
      <Select
        defaultValue={framework}
        style={{ width: "50%", marginBottom: "1rem" }}
        value={framework}
        onChange={onLanguageChange}
      >
        <Option value="react">React</Option>
        <Option value="vue">Vue</Option>
        <Option value="angular">Angular</Option>
        <Option value="vanilla">Vanilla JS</Option>
      </Select>

      <SandpackProvider
        theme="dark"
        template={framework as SandpackPredefinedTemplate}
        {...(sandpackFiles ? { files: { ...sandpackFiles } } : {})}
      >
        <Title level={4} style={{ marginBottom: "1rem" }}>
          Select Read Only Files
        </Title>
        <ReadOnlyFilesSelector
          selectedFiles={selectedFiles}
          onFileChange={handleFileChange}
        />
        <SandpackWrapper selectedFiles={selectedFiles} />
        <Content style={{ padding: "0.5rem 0" }}>
          <SandpackLogger
            onSave={onSaveTemplate}
            selectedFiles={selectedFiles}
          />
        </Content>
      </SandpackProvider>
    </Layout>
  );
};

interface SandpackWrapperProps {
  selectedFiles: string[];
}

const SandpackWrapper: React.FC<SandpackWrapperProps> = React.memo(
  ({ selectedFiles }) => {
    return (
      <SandpackLayout>
        <SandpackFileExplorer />
        <CustomSandpackCodeEditor selectedFiles={selectedFiles} />
        <SandpackPreview />
      </SandpackLayout>
    );
  }
);

interface ReadOnlyFilesSelectorProps {
  selectedFiles: string[];
  onFileChange: (value: string[]) => void;
}

const ReadOnlyFilesSelector: React.FC<ReadOnlyFilesSelectorProps> = ({
  selectedFiles,
  onFileChange,
}) => {
  const { sandpack } = useSandpack();
  const fileOptions = Object.keys(sandpack.files);

  return (
    <Select
      mode="multiple"
      style={{ width: "50%", marginBottom: "1rem" }}
      placeholder="Select files"
      value={selectedFiles}
      onChange={(selected) => {
        onFileChange(selected);
      }}
      options={fileOptions.map((file) => ({ label: file, value: file }))}
    />
  );
};

interface CustomSandpackCodeEditorProps {
  selectedFiles: string[];
}

const CustomSandpackCodeEditor: React.FC<CustomSandpackCodeEditorProps> = ({
  selectedFiles,
}) => {
  const { sandpack } = useSandpack();
  const { activeFile } = sandpack;

  const readOnlyExtension = useMemo(() => {
    const isEditable = !selectedFiles.includes(activeFile);
    const extension: Extension = EditorView.editable.of(isEditable);
    return [extension];
  }, [selectedFiles, activeFile]);

  return (
    <SandpackCodeEditor showTabs closableTabs extensions={readOnlyExtension} />
  );
};

const SandpackLogger: React.FC<SandpackLoggerProps> = ({
  onSave,
  selectedFiles,
}) => {
  const { sandpack } = useSandpack();
  const { files } = sandpack;

  const handleSubmit = () => {
    const fileStates = Object.entries(files).map(([filePath, fileData]) => ({
      path: filePath,
      code: fileData.code,
      readOnly: selectedFiles.includes(filePath),
    }));
    onSave(fileStates);
  };

  return (
    <div
      style={{ display: "flex", justifyContent: "right", padding: "0.5rem 0" }}
    >
      <Button onClick={handleSubmit} type="primary">
        Save Template
      </Button>
    </div>
  );
};

export default CreateQuestionForm;
