import {
  SectionedItem,
  SideBarItem,
  UserRoleActions,
  permissionAccessConstants,
  permissionGroupAccessConstants,
  reportPermissions,
} from "@idsk/components-props";
import { Header, SideBar } from "@idsk/components-ui";
import { RootRouter } from "../Router";
import { THEME } from "../theme";
import { useContext, useState } from "react";
import { Auth } from "aws-amplify";
import {
  AuthContext,
  AuthContextObject,
  UserContext,
} from "@idsk/ui-core-framework";
import { useLocation, useNavigate } from "react-router";
import {
  BarChartOutlined,
  ContainerOutlined,
  DashboardOutlined,
  FileTextOutlined,
  ReadOutlined,
  UserAddOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { Layout } from "antd";
import { CampusLogo } from "../assets/CampusLogo.icon";
import { withTheme } from "styled-components";
import { useGetUserDetails } from "../auth";
import "./HomeContainer.scss";

const onContactClick = () => {
  window.open("mailto:contact@interviewdesk.in");
};

export const HomeContainer = withTheme((props: any) => {
  const userAuth: AuthContextObject = useGetUserDetails();
  const { role } = useContext(UserContext);
  function checkViewAccess(groupName: string, permissionName: string) {
    return (
      (userAuth.roles
        .find((role) => role.name === groupName)
        ?.permissions.find((permission) => permission.name === permissionName)
        ?.actions.includes(UserRoleActions.View) ??
        false) &&
      role
    );
  }

  const [collapsed, setCollapsed] = useState(false);
  const { mail, clientId, setUser } = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();

  const onSideBarItemClick = (
    sectionId: string,
    sideBarItem: SideBarItem
  ): void => {
    if (sideBarItem.id === "questions") {
      navigate(`clients/self/questions`);
    } else if (sideBarItem.id === "dashboard") {
      navigate(`/dashboard`);
    } else if (sideBarItem.id === "assessments") {
      navigate(`clients/self/assessments`);
    } else if (sideBarItem.id === "assessmentAdministrations") {
      navigate(`clients/${clientId}/assessmentAdministrations`);
    } else if (sideBarItem.id === "assessmentUserGuide") {
      navigate("/userguide");
    } else if (sideBarItem.id === "students") {
      navigate("clients/self/students");
    } else if (sideBarItem.id === "courses") {
      navigate("/lms");
    } else if (sideBarItem.id === "courseAdmin") {
      navigate("/Admin");
    } else if (sideBarItem.id === "rolesAndPermission") {
      navigate(`/manage/roles`);
    } else if (sideBarItem.id === "users") {
      navigate(`/manage/users`);
    } else if (sideBarItem.id === "usergroups") {
      navigate(`/manage/groups`);
    } else if (sideBarItem.id === "college") {
      navigate(`/manage/college`);
    } else if (sideBarItem.id === "candidate-reports") {
      navigate(`/manage/candidate-reports`);
    } else {
      navigate(sideBarItem.id);
    }
  };

  const onSignOutClick = () => {
    Auth.signOut()
      .then((data) => {
        setUser?.(null);
        navigate("/");
      })
      .catch((err) => console.log(err));
  };

  let userManagamentMenuItems: SideBarItem[] = [];

  if (
    checkViewAccess(
      permissionGroupAccessConstants.general,
      permissionAccessConstants.organizations
    )
  ) {
    userManagamentMenuItems.push({
      icon: FileTextOutlined,
      id: "college",
      title: "Colleges",
    });
  }
  if (
    checkViewAccess(
      permissionGroupAccessConstants.userManagement,
      permissionAccessConstants.users
    )
  ) {
    userManagamentMenuItems.push({
      icon: UserAddOutlined,
      id: "users",
      title: "Users",
    });
  }

  if (
    checkViewAccess(
      permissionGroupAccessConstants.userManagement,
      permissionAccessConstants.groups
    )
  ) {
    userManagamentMenuItems.push({
      icon: UsergroupAddOutlined,
      id: "usergroups",
      title: "Groups",
    });
  }

  // if (
  //   checkViewAccess(
  //     permissionGroupAccessConstants.userManagement,
  //     permissionAccessConstants.rolesAndPermissions
  //   )
  // ) {
  //   userManagamentMenuItems.push({
  //     icon: FileTextOutlined,
  //     id: "rolesAndPermission",
  //     title: "Roles and Permissions",
  //   });
  // }

  const items: SectionedItem[] = [
    {
      id: "home",
      title: "Home",
      data: [
        {
          icon: DashboardOutlined,
          id: "dashboard",
          title: "Dashboard",
        },
      ],
    },
    {
      id: "assessment",
      title: "ASSESSMENT",
      data: [
        {
          icon: ContainerOutlined,
          id: "questions",
          title: "Questions",
        },
        {
          icon: ReadOutlined,
          id: "assessments",
          title: "Assessments",
        },
        {
          icon: DashboardOutlined,
          id: "assessmentAdministrations",
          title: "Administrations",
        },
      ],
    },
    {
      id: "lms",
      title: "COURSE MANAGEMENT",
      data: [
        {
          icon: ReadOutlined,
          id: "courses",
          title: "Courses",
        },
        {
          icon: ContainerOutlined,
          id: "courseAdmin",
          title: "Administrations",
        },
      ],
    },
  ];

  if (
    checkViewAccess(
      permissionGroupAccessConstants.reports,
      permissionAccessConstants.reports
    )
  ) {
    items[0].data.push({
      icon: BarChartOutlined,
      id: "candidate-reports",
      title: "Reports",
    });
  }
  const featureTestingClientIds = ["idsk.id.saju.client"];

  if (featureTestingClientIds.includes(clientId)) {
    // items.push({
    // });
  }

  items[0].data.push({
    icon: FileTextOutlined,
    id: "assessmentUserGuide",
    title: "User Guide",
  });

  if (userManagamentMenuItems.length > 0) {
    items.push({
      id: "usermanagement",
      title: "USER MANAGEMENT",
      data: userManagamentMenuItems,
      expanded: true,
    });
  }
  const ProfileObject = {
    displayName: mail.split("@")[0],
    avatar: mail.split("@")[0],
  };

  enum NavMenuIconState {
    EXPANDED,
    COLLAPSED,
    DISABLED,
  }

  const selectedSideBarTab =
    location.pathname.split("/")[
      location.pathname.split("/").indexOf("clients") + 2
    ];

  const onNavMenuIconClick = (a: NavMenuIconState) => {
    setCollapsed(a === NavMenuIconState.COLLAPSED);
  };

  const { Content } = Layout;
  const isRootRouterPath =
    location.pathname.startsWith("/lms") ||
    location.pathname.startsWith("/student") ||
    location.pathname.startsWith("/admin");

  return (
    <Layout style={{ height: "100vh", overflow: "hidden" }}>
      <SideBar
        collapsed={collapsed}
        onItemClick={onSideBarItemClick}
        items={items}
        onContactClick={onContactClick}
        theme={THEME}
        whiteLabelLogo={props.theme.companyLogo ?? undefined}
        onLogoClick={() => navigate(`/`)}
        logo={CampusLogo}
      />
      <Layout>
        <Header
          onSignOutClick={onSignOutClick}
          onNavMenuIconClick={onNavMenuIconClick}
          profile={ProfileObject}
          navMenuIconState={NavMenuIconState.EXPANDED}
          theme={THEME}
        />
        <Content className={isRootRouterPath ? "" : "custom-content"}>
          <AuthContext.Provider value={{ roles: userAuth.roles }}>
            <RootRouter />
          </AuthContext.Provider>
        </Content>
      </Layout>
    </Layout>
  );
});
