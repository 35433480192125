import QuestionFormPage from "./QuestionFormPage";
import { SandpackProvider } from "@codesandbox/sandpack-react";
import CodeIdeReportsApp from "./CodeIdeReports/CodeIdeReports";
import { AssessmentSessionLayoutProps } from "./CodeIdeReports/DataFetchers/AssessmentSession.df";

const WrappedCodeIdeReports = (props: AssessmentSessionLayoutProps) => {
  return (
    <SandpackProvider theme="dark">
      <CodeIdeReportsApp {...props} />
    </SandpackProvider>
  );
};

export { WrappedCodeIdeReports as CodeIdeReports, QuestionFormPage };
