import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import {
  SandpackCodeEditor,
  SandpackLayout,
  SandpackPreview,
  SandpackProvider,
  SandpackPredefinedTemplate,
  useSandpack,
  SandpackTests,
} from "@codesandbox/sandpack-react";
import SandpackFileExplorer from "@rainetian/sandpack-file-explorer";
import {
  getStageType,
  StageType,
  getLoadableAssetsCampusLinkFromPath,
  getAxiosInstance,
} from "@idsk/ui-core-framework";
import Split from "react-split";
import { Layout, Button, Select, message, Spin } from "antd";
import { FileOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { bufferDecode } from "@idsk/components-props";
import JSZip from "jszip";
import { AssessmentSessionLayoutProps } from "./DataFetchers/AssessmentSession.df";
import "./CodeIdeReports.css";

const { Sider, Content } = Layout;

interface CodeTemplate {
  path: string;
  code: string;
  state: string;
  readOnly: boolean;
}

interface Question {
  id?: string;
  description?: string;
  questionTitle?: string;
  codeTemplatesLink?: string;
  framework?: string;
  clientId?: string;
  codeTemplates?: FileState[];
  providedAnswer?: string;
  totalTestCases?: number;
  passedTestCases?: number;
}

interface FileState {
  path: string;
  code: string;
  readOnly?: boolean;
}

interface TestResult {
  tests?: Record<string, any>;
  describes?: Record<string, TestResult>;
}

const CodeIdeReports: React.FC<AssessmentSessionLayoutProps> = (props) => {
  const [assessment, setAssessment] = useState<any>({});
  const [uiState, setUiState] = useState({
    isExplorerOpen: false,
    isQuestionOpen: false,
    showTestCases: true,
    codeIdeLoading: false,
    codeTemplates: [] as FileState[],
    template: "react" as SandpackPredefinedTemplate,
    selectedQuestion: {} as Question,
    previousFileStates: [] as FileState[],
    score: null as { total: number; passed: number; failed: number } | null,
    questionsOptions: [] as Question[],
  });

  const environment =
    getStageType() === StageType.DEVELOPMENT ? "dev" : getStageType();

  const { sandpack } = useSandpack();
  const { updateFile, files, activeFile } = sandpack;

  const filesRef = useRef<Record<string, CodeTemplate>>({});
  const testResultsRef = useRef<TestResult | null>(null);

  // Helper function to normalize file paths
  const normalizeFilePath = (filename: string) => {
    const normalizedFilename = filename.replace(/\\/g, "/");
    return normalizedFilename.startsWith("/")
      ? normalizedFilename
      : "/" + normalizedFilename;
  };

  useEffect(() => {
    if (!uiState.selectedQuestion?.codeTemplatesLink) {
      return;
    }

    const fetchCodeTemplates = async () => {
      try {
        setUiState((prevState) => ({
          ...prevState,
          codeIdeLoading: true,
        }));

        const questionId = uiState.selectedQuestion.id;
        const clientId = uiState.selectedQuestion.clientId;
        const codeTemplatesS3Prefix = `/assets/idesk-${environment}-assessment/frontendframework/questions/clients/${clientId}/${questionId}/`;
        const codeTemplatesPath = `${codeTemplatesS3Prefix}codeTemplates.zip`;
        const codeTemplatesUrl =
          getLoadableAssetsCampusLinkFromPath(`${codeTemplatesPath}`) ?? "";

        const s3Response = await getAxiosInstance().get(codeTemplatesUrl, {
          headers: { useMirage: false },
        });

        if (!s3Response.data) {
          message.error("Invalid code templates URL.");
          setUiState((prevState) => ({
            ...prevState,
            codeIdeLoading: false,
          }));
          return;
        }

        const response = await fetch(s3Response.data, { method: "GET" });
        if (!response.ok) {
          const errorText = await response.text();
          console.error(
            `Failed to fetch code templates. Status: ${response.status}`
          );
          console.error("Error Response:", errorText);
          throw new Error(
            `Failed to fetch code templates. Status: ${response.status}`
          );
        }

        const zipBlob = await response.blob();
        const zip = await JSZip.loadAsync(zipBlob);
        const fileStates: FileState[] = [];

        await Promise.all(
          Object.keys(zip.files).map(async (filename) => {
            const file = zip.files[filename];
            if (!file.dir) {
              try {
                const content = await file.async("string");

                // Normalize the file path
                const filePath = normalizeFilePath(filename);

                fileStates.push({
                  path: filePath,
                  code: content,
                  readOnly: false,
                });
              } catch (fileError) {
                console.error(`Error reading file ${filename}:`, fileError);
              }
            }
          })
        );

        setUiState((prevState) => ({
          ...prevState,
          codeIdeLoading: false,
          codeTemplates: fileStates,
        }));
      } catch (error) {
        console.error("Error fetching code templates:", error);
        message.error("Failed to fetch code templates.");
        setUiState((prevState) => ({
          ...prevState,
          codeIdeLoading: false,
        }));
      }
    };

    fetchCodeTemplates();
  }, [uiState.selectedQuestion?.codeTemplatesLink, environment]);

  const mapLanguageToTemplate = (
    framework?: string
  ): SandpackPredefinedTemplate | undefined => {
    switch (framework?.toLowerCase()) {
      case "react":
        return "react";
      case "vue":
        return "vue";
      case "angular":
        return "angular";
      case "svelte":
        return "svelte";
      case "vanilla":
        return "vanilla";
      default:
        return "react";
    }
  };

  useEffect(() => {
    const assessmentResponse = props?.asyncAssessmentSessionUi?.getResponse();
    setAssessment(assessmentResponse);
    const sections = assessmentResponse?.sections;
    const questions =
      sections?.flatMap((section: any) => section.questions) || [];

    if (questions.length > 0) {
      const firstQuestion = questions[0];
      setUiState((prevState) => ({
        ...prevState,
        questionsOptions: questions,
        selectedQuestion: firstQuestion,
        template: mapLanguageToTemplate(firstQuestion.framework) || "react",
      }));
    }
  }, [props.asyncAssessmentSessionUi.getResponse()]);

  const updateFiles = useCallback(
    (codeTemplates: Record<string, CodeTemplate>) => {
      Object.entries(codeTemplates).forEach(([filePath, fileContent]) => {
        if (filesRef.current[filePath]?.code !== fileContent.code) {
          updateFile(filePath, fileContent.code);
          filesRef.current[filePath] = fileContent;
        }
      });
    },
    [updateFile]
  );

  useEffect(() => {
    if (uiState.codeTemplates.length > 0) {
      const codeTemplates: Record<string, CodeTemplate> =
        uiState.codeTemplates.reduce(
          (acc: Record<string, CodeTemplate>, fileState: FileState) => {
            acc[fileState.path] = {
              path: fileState.path,
              code: fileState.code,
              state: "existing",
              readOnly: fileState.readOnly ?? false,
            };
            return acc;
          },
          {}
        );

      filesRef.current = codeTemplates;
      updateFiles(codeTemplates);
    }
  }, [uiState.codeTemplates, updateFiles]);

  useEffect(() => {
    if (activeFile && activeFile.includes(".test.")) {
      handleRunTests();
    }
  }, [activeFile, files]);

  const ActiveFileLogger: React.FC = () => {
    const { sandpack } = useSandpack();
    const { activeFile, files } = sandpack;

    useEffect(() => {
      if (activeFile && activeFile.includes(".test.")) {
        const fileData = files[activeFile];
        const updatedCode = fileData?.code;

        if (updatedCode) {
          if (!filesRef.current[activeFile]) {
            filesRef.current[activeFile] = {
              path: activeFile,
              code: updatedCode,
              state: "existing",
              readOnly: false,
            };
          } else if (filesRef.current[activeFile].code !== updatedCode) {
            filesRef.current[activeFile].code = updatedCode;
            handleRunTests();
          }
        }
      }
    }, [activeFile, files]);

    return null;
  };

  const handleRunTests = useCallback(() => {
    setUiState((prevState) => ({ ...prevState, showTestCases: true }));
  }, []);

  const handleCloseTests = useCallback(() => {
    setUiState((prevState) => ({ ...prevState, showTestCases: false }));
  }, []);

  const handleTestResults = useCallback(
    (results: Record<string, TestResult>) => {
      testResultsRef.current = results;

      let totalTests = 0;
      let passedTests = 0;
      let failedTests = 0;

      const traverseResults = (node: TestResult) => {
        if (!node) return;

        if (node.tests && typeof node.tests === "object") {
          Object.values(node.tests).forEach((test: any) => {
            totalTests++;
            test.status === "pass" ? passedTests++ : failedTests++;
          });
        }

        if (node.describes && typeof node.describes === "object") {
          Object.values(node.describes).forEach(traverseResults);
        }
      };

      Object.values(results).forEach(traverseResults);

      const score = {
        total: totalTests,
        passed: passedTests,
        failed: failedTests,
      };

      setUiState((prevState) => ({
        ...prevState,
        score,
      }));
    },
    []
  );

  const handleQuestionChange = useCallback(
    async (
      fileStates: FileState[],
      totalTestCases: number,
      passedTestCases: number,
      value: any
    ) => {
      const selectedQuestion = uiState.questionsOptions.find(
        (q) => q.id === value
      );
      if (!selectedQuestion) {
        message.error("Selected question not found.");
        return;
      }
      if (
        JSON.stringify(fileStates) !==
        JSON.stringify(uiState.previousFileStates)
      ) {
        setUiState((prevState) => ({
          ...prevState,
          selectedQuestion: {
            ...selectedQuestion,
            status: "IN_PROGRESS",
          },
          template:
            mapLanguageToTemplate(selectedQuestion.framework) || "react",
          codeTemplates: [],
          previousFileStates: fileStates,
          score: null,
        }));
      }
    },
    [uiState.questionsOptions, uiState.score, files, filesRef.current]
  );

  const SandpackQuestionLogger: React.FC<{
    onQuestionChange: (
      fileStates: FileState[],
      totalTestCases: number,
      passedTestCases: number,
      value: any
    ) => void;
  }> = ({ onQuestionChange }) => {
    const { sandpack } = useSandpack();
    const { files, activeFile } = sandpack;

    const handleQuestionChange = (value: string) => {
      const fileStates: FileState[] = Object.entries(files).map(
        ([filePath, fileData]) => ({
          path: filePath,
          code: fileData.code,
          readOnly: filesRef.current[filePath]?.readOnly ?? false,
        })
      );

      const totalTestCases = uiState.score?.total || 0;
      const passedTestCases = uiState.score?.passed || 0;
      onQuestionChange(fileStates, totalTestCases, passedTestCases, value);

      if (activeFile && activeFile.includes(".test.")) {
        handleRunTests();
      }
    };

    return (
      <div className="question-logger-container">
        <Select
          style={{ width: 500 }}
          placeholder="Select a Question"
          value={uiState.selectedQuestion?.id}
          onChange={handleQuestionChange}
          options={uiState.questionsOptions.map((question) => ({
            label: question?.questionTitle,
            value: question?.id,
          }))}
          aria-label="Select a Question"
        />
      </div>
    );
  };

  const SandpackLogger: React.FC<{}> = () => {
    return (
      <div className="test-logger-container">
        <Button
          onClick={handleRunTests}
          type="primary"
          className="logger-button"
        >
          Show Test Cases
        </Button>
        <Button
          onClick={handleCloseTests}
          type="primary"
          className="logger-button"
        >
          Hide Test Cases
        </Button>
      </div>
    );
  };

  const memoizedFiles = useMemo(() => {
    const codeTemplates: Record<string, CodeTemplate> =
      uiState.codeTemplates.reduce(
        (acc: Record<string, CodeTemplate>, fileState: FileState) => {
          acc[fileState.path] = {
            path: fileState.path,
            code: fileState.code,
            state: "existing",
            readOnly: fileState.readOnly ?? false,
          };
          return acc;
        },
        {}
      );

    return codeTemplates;
  }, [uiState.codeTemplates]);

  return uiState.codeIdeLoading ? (
    <div className="loadingContainer">
      <Spin size="large" tip="Loading..." />
    </div>
  ) : (
    <SandpackProvider
      template={uiState.template}
      files={memoizedFiles}
      theme="dark"
    >
      <SandpackQuestionLogger onQuestionChange={handleQuestionChange} />
      <Layout
        className={`${
          !uiState.showTestCases ? "content-layout-height" : ""
        } content-layout`}
      >
        <div className="code-sidebar">
          {uiState.selectedQuestion && (
            <Button
              type="primary"
              icon={<QuestionCircleOutlined />}
              onClick={() =>
                setUiState((prevState) => ({
                  ...prevState,
                  isQuestionOpen: !prevState.isQuestionOpen,
                  isExplorerOpen: false,
                }))
              }
              className="icon-button"
              aria-label="Toggle Question Sidebar"
            />
          )}
          <Button
            type="primary"
            icon={<FileOutlined />}
            onClick={() =>
              setUiState((prevState) => ({
                ...prevState,
                isExplorerOpen: !prevState.isExplorerOpen,
                isQuestionOpen: false,
              }))
            }
            className="icon-button"
            aria-label="Toggle File Explorer"
          />
        </div>
        <Sider
          collapsible
          collapsed={!uiState.isQuestionOpen}
          width={400}
          collapsedWidth={0}
          trigger={null}
          className="question-explorer"
        >
          <div
            className="question-content"
            style={{
              height: uiState.showTestCases ? "calc(100vh - 14.5rem)" : "84vh",
            }}
          >
            <h2>{uiState.selectedQuestion?.questionTitle}</h2>
            <div
              dangerouslySetInnerHTML={{
                __html: bufferDecode(
                  uiState.selectedQuestion?.description || ""
                ),
              }}
            />
          </div>
        </Sider>
        <Sider
          collapsible
          collapsed={!uiState.isExplorerOpen}
          width={300}
          collapsedWidth={0}
          trigger={null}
          className="file-explorer"
        >
          <div className="file-explorer-content">
            <SandpackFileExplorer
              style={{
                height: uiState.showTestCases
                  ? "calc(100vh - 22.3rem)"
                  : "calc(100vh - 9.75rem)",
                fontSize: "14px",
              }}
            />
          </div>
        </Sider>
        <Content
          className={`${uiState.isExplorerOpen ? "content" : "shifted"}`}
        >
          <SandpackLayout
            style={{
              height: uiState.showTestCases
                ? "calc(100vh - 26rem)"
                : "calc(100vh - 12.8rem)",
            }}
          >
            <Split
              className="split"
              sizes={[50, 50]}
              minSize={200}
              gutterSize={3}
            >
              <div className="editor-pane">
                <SandpackCodeEditor
                  style={{ height: "100%" }}
                  showLineNumbers
                  showTabs
                  closableTabs
                  showInlineErrors
                  wrapContent
                />
              </div>
              <div className="preview-pane">
                <SandpackPreview style={{ height: "100%" }} />
              </div>
            </Split>
          </SandpackLayout>
          <ActiveFileLogger />
          <SandpackLogger />
        </Content>
      </Layout>
      {uiState.showTestCases && (
        <div
          className={`testRunner ${
            uiState.showTestCases ? "show-testcase" : "hide-testcase"
          }`}
        >
          <SandpackTests verbose onComplete={handleTestResults} />
        </div>
      )}
    </SandpackProvider>
  );
};

export default CodeIdeReports;
