import {
  AsyncResponse,
  AuthContextObject,
  UserContext,
  isResponseError,
  isResponseLoading,
  useQueryWithMetaData,
} from "@idsk/ui-core-framework";
import { useContext } from "react";
import { GetUserRoleDAO } from "./GetUserDetailsDAO";

export const useGetUserDetails = () => {
  const { clientId, id } = useContext(UserContext);
  const { response } = useQueryWithMetaData<AsyncResponse<AuthContextObject>>(
    new GetUserRoleDAO(clientId, id!)
  );
  if (isResponseLoading(response) || isResponseError(response)) {
    return { roles: [] };
  }
  return response;
};
